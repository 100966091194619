import { Capacitor } from "@capacitor/core";
import { KeepAwake } from "@capacitor-community/keep-awake";

export default {
  instance: KeepAwake,

  init() {
    if (Capacitor.getPlatform() === "web") return;
    KeepAwake.keepAwake();
  },

  install(Vue) {
    Vue.prototype.$plugins.keepAwake = KeepAwake;
  },
};
